import styled from 'styled-components';
import { colors } from '@/styles';

export const Container = styled.div`
  background: ${colors.white};
  pointer-events: auto;
  border-radius: 12px;
  padding: 0px 16px;
  position: relative;
  width: 90%;
  max-width: 310px;
  margin: 0 auto;
  .icon {
    position: absolute;
    width: 116px;
    height: 116px;
    left: 50%;
    transform: translateX(-50%);
    top: -48px;
  }
  .title1 {
    font-size: 20px;
    font-weight: 600;
    color: ${colors.primaryBlack};
    line-height: 25px;
    text-align: center;
  }
  .title2 {
    font-size: 18px;
    font-weight: 600;
    color: ${colors.primaryBlack};
    line-height: 25px;
    text-align: center;
  }
  .title3 {
    font-size: 16px;
    font-weight: 600;
    color: ${colors.primaryBlack};
    line-height: 25px;
    text-align: center;
  }
  .paragraph {
    font-size: 16px;
    color: ${colors.primaryBlack};
    line-height: 22px;
    padding-top: 10px;
    text-align:left;
  }
  .text {
    font-size: 14px;
    color:${colors.textTertiary};
    line-height: 20px;
    padding-top: 10px;
    text-align: left;
  }
  .button-group {
    padding: 32px 0px;
    .ant-btn {
      width: 124px;
      font-size: 16px;
      line-height: 16px;
    }
  }
`;
export default {};
