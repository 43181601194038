import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Modal, message, Spin, Button,
} from 'antd';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import wx from 'weixin-js-sdk';
import { httpRequest } from '@/utils';
import { amountFormat, localStorageKeys } from '@/constants';
import authStore from '@/stores/auth';
import { MyModal } from '@/components/popup';
import "./index.less";

import agreement from '../images/agreement.png';

import { MyFooter, wxTagStyles } from './styles';

import BindCardModal from './bindCardModal';
const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isCardBind, setIsCardBind] = useState(false);
  const [miniProgramPath, setMiniProgramPath] = useState();
  const isAuth = sessionStorage.getItem(localStorageKeys.bindState) === 'true';
  const [balance, setBalance] = useState(0);
  const token = sessionStorage.getItem(localStorageKeys.token);
  const [hiddenViewCard, setHiddenViewCard] = useState(true);

  const getStaffInfo = async () => {
    try {
      const res = await httpRequest.get('/client/staff/info');
      if (res.code === 0) {
        authStore.setProfile({
          idNo: res.data?.idNo,
          name: res.data?.name,
        });
        authStore.setCoverPoint({
          userId: res.data?.userId,
        });
      } else {
        throw new Error(res.msg);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const getCardBindStatus = async () => {
    const resCheck = await httpRequest.post('/client/staff/payrollCard/restrict/check');
    try {
      if (resCheck.code === 0) {
        setHiddenViewCard(resCheck.data);
      } else {
        throw new Error(resCheck.msg);
      }
    } catch (error) {
      message.error(error.message);
    }

    const res = await httpRequest.get('/client/staff/payrollCard/auth');
    try {
      if (res.code === 0) {
        setIsCardBind(res?.data);
      } else {
        throw new Error(res.msg);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const getBalance = async () => {
    try {
      const res = await httpRequest.get('/client/staff/withdrawAmt');
      if (res.code === 0) {
        setBalance(res?.data);
      } else {
        throw new Error(res.msg);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const getMiniProgramPath = async () => {
    const res = await httpRequest.get('/client/staff/payrollCard/peek/url');
    try {
      if (res.code === 0) {
        setMiniProgramPath(res?.data);
      } else {
        throw new Error(res.msg);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const weAppRef = useRef(null);

  const init = async () => {
    try {
      setLoading(true);
      const res = await httpRequest.get('/client/index/info');
      if (res.code === 0) {
        authStore.setCompany({
          tenantName: res.data?.tenantName,
        });
        sessionStorage.setItem(localStorageKeys.tenantId, res.data?.tenantId);
        sessionStorage.setItem(localStorageKeys.openId, res.data?.openId);
        if (isAuth) {
          getStaffInfo();
          // getHiddenViewStatus();
          getBalance();
          getCardBindStatus();
          getMiniProgramPath();
        }
      } else {
        throw new Error(res.msg);
      }
    } catch (err) {
      message.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      init();
    }
  }, [token]);

  const handleDetailed = () => {
    navigate('/detailed');
  };

  const [modalVisible, setModalVisible] = useState({
    privacyAgreement: localStorage.getItem(localStorageKeys.privacyAgreed) !== 'true',
    bindCard: false,
  });
  const handleModalVisibleChange = (name, visible) => {
    setModalVisible({
      ...modalVisible,
      [name]: visible,
    });
  };

  const handleCardClick = () => {
    if (!isAuth) {
      navigate('/identity');
      return;
    }
    if (!isCardBind) {
      handleModalVisibleChange('bindCard', true);
    }
  };

  const handleIdentityClick = (link) => {
    if (!isAuth) {
      message.warning('您的账号还未激活，请先完成实名认证！');
      return;
    }
    navigate(link);
  };

  const handleWithdraw = () => {
    if (isAuth && isCardBind) {
      navigate('/withdraw');
      return;
    }
    handleModalVisibleChange('bindCard', true);
  };

  const handleSalary = (link) => {
    if (isAuth && isCardBind) {
      navigate(link);
      return;
    }
    if (!isAuth) {
      navigate('/identity');
      return;
    }
    handleModalVisibleChange('bindCard', true);
  };

  const handleContract = (link) => {
    if (!isAuth) {
      message.warning('您的账号还未激活，请先完成实名认证！');
      return;
    }
    navigate(link);
  };

  const handleMenuClick = (type) => {
    switch (type) {
      case 'ID':
        handleIdentityClick('/manager');
        break;
      case 'CARD':
        handleCardClick();
        break;
      case 'SALARY':
        handleSalary('/salary');
        break;
      case 'CONTRACT':
        handleContract('/contract');
        break;
      default:
        break;
    }
  };

  const handlePrivacyModalConfirm = () => {
    handleModalVisibleChange('privacyAgreement', false);
    localStorage.setItem(localStorageKeys.privacyAgreed, 'true');
  };

  const Agreement = () => (
    <>
      欢迎使用“云彩人资科研”，我们非常重视您的个人信息和隐私保护。在您使用“云彩人资科研”服务之前。请仔细阅读
      <a href="https://portal.ycrzky.com/privacyPolicy">《云彩人资科研隐私政策》</a>
      和
      <a href="https://portal.ycrzky.com/userAgreement">《云彩人资科研用户协议》</a>
      ，我们将严格按照您同意的条款使用您的个人信息，以便为您提供服务。
    </>
  );

  useEffect(() => {
    if (isAuth && isCardBind) {
      const error = () => {
        message.error('打开小程序失败，请重试');
      };
      wx.ready(() => {
        weAppRef.current?.addEventListener('error', error);
      });
      return () => {
        weAppRef.current?.removeEventListener('error', error);
      };
    }
    return null;
  }, [isAuth, isCardBind]);

  const handleMiniProgramLaunched = () => {
    Modal.confirm({
      title: '实名认证已完成?',
      okText: '是',
      cancelText: '否',
      onOk: getCardBindStatus,
      onCancel: getCardBindStatus,
    });
  };

  const handleDisagree = () => {
    wx.ready(() => {
      wx.closeWindow();
    });
  };

  //去激活
  const gotoActivate = () => navigate('/identity');

  return (
    <Spin spinning={loading}>
      <div className="home-container">
        <div className="top-box">
          <p>
            您好！
            {authStore.profile?.name}
          </p>
          <h3>{authStore.company?.tenantName}</h3>
        </div>
        <div className="bottom-box">
          <div className="money-box">
            <div className="content-box">
              {isAuth ? <div className='auth-box'>
                <text className='get-money-text' onClick={handleDetailed}>收支明细</text>
                <div className='auth-title-box'>
                  <text className='auth-title'>账户余额(元)</text>
                  <text className='auth-text'>{numeral(balance).format(amountFormat)}</text>
                </div>
                <div className='auth-button-box'>
                  <Button type="primary" color="#409eff" onClick={handleWithdraw} style={{width: '100px', fontSize: '18px', lineHeight: '24px'}} size="middle" shape="round">
                    提现
                  </Button>
                </div>
              </div> : <div className='no-auth-box'>
                <div className='no-auth-title-box'>
                  <text className='no-auth-title'>激活微信账号，开通账户</text>
                  <text className='no-auth-text'>激活微信账号后已发放至账户的余额会自动显示</text>
                </div>
                <Button type="primary" color="#409eff" onClick={gotoActivate} ghost style={{width: '100px'}} size="small" shape="round">
                  去激活
                </Button>
              </div>}
            </div>
          </div>
          <div className="function-box">
            <div onClick={() => handleMenuClick('ID')} className="content-box">
              <div className="inner-content-box-info">
                <span className="text">信息管理</span>
                <div className="info-image"></div>
              </div>
            </div>
            {!hiddenViewCard && <div onClick={() => handleMenuClick('CARD')} className="content-box">
              <div className="inner-content-wgk-info">
                <span className="text">微工卡</span>
                <div className="wgk-image"></div>
                {isAuth && isCardBind && <wx-open-launch-weapp
                  ref={weAppRef}
                  username={process.env.REACT_APP_WECHAT_MINI_PROGRAM_ID}
                  path={miniProgramPath}
                  env-version={process.env.REACT_APP_WECHAT_MINI_PROGRAM_ENV}
                  style={wxTagStyles.tag}
                >
                  <script type="text/wxtag-template">
                    <div style={wxTagStyles.content}/>
                  </script>
                </wx-open-launch-weapp>}
              </div>
            </div>}
            <div onClick={() => handleMenuClick('SALARY')} className="content-box">
              <div className="inner-content-salary-info">
                <span className="text">工资条</span>
                <div className="salary-image"></div>
              </div>
            </div>
            <div onClick={() => handleMenuClick('CONTRACT')} className="content-box">
              <div className="inner-content-contract-info">
                <span className="text">电子合同</span>
                <div className="contract-image"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MyFooter>
        <ul>
          <li className="home">
            <p className="active">首页</p>
          </li>
          <Link style={{ color: '#9AA4AF', width: '50%', display: 'block' }} to="advance">
            <li className="advance">
              <p>预支</p>
            </li>
          </Link>
        </ul>
      </MyFooter>
      <BindCardModal
        visible={modalVisible.bindCard}
        onClose={() => handleModalVisibleChange('bindCard', false)}
        isAuth={isAuth}
        onConfirm={() => navigate('/identity')}
        onLaunched={handleMiniProgramLaunched}
      />
      <Modal
        visible={modalVisible.privacyAgreement}
        modalRender={() => (
          <MyModal
            icon={agreement}
            okText="确定"
            noText="取消"
            title="用户协议"
            paragraph={<Agreement />}
            text=" 如您同意此政策，请点击“同意”并开始使用我们的产品和服务，我们将尽全力保护您的个人信息安全。"
            onNo={handleDisagree}
            onOk={handlePrivacyModalConfirm}
            contentStyle={{
              paddingTop: '60px',
              background: 'linear-gradient(180deg, #E9F7FF 0%, #FFFFFF 100%)',
            }}
          />
        )}
      />
    </Spin>
  );
};

export default observer(Home);
