import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute } from '@components';

const Advance = lazy(() => import('./advance'));
const Apply = lazy(() => import('./apply'));
const Record = lazy(() => import('./record'));

export default {
  path: 'advance',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <ProtectedRoute title="预支工资" component={<Advance />} />,
    },
    {
      path: 'apply',
      element: <ProtectedRoute title="申请预知" component={<Apply />} />,
    },
    {
      path: 'applyRecord',
      element: <ProtectedRoute title="申请记录" component={<Record />} />,
    },
  ],
};
