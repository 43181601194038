import React, { lazy } from 'react';
import { ProtectedRoute } from '@components';
import { DashboardOutlined } from '@ant-design/icons';

const Manager = lazy(() => import('./manager'));

export default {
  path: 'manager',
  element: <ProtectedRoute title="身份管理" component={<Manager />} />,
  options: {
    title: '信息管理',
    key: 'manager',
    icon: <DashboardOutlined />,
    link: '/',
  },
};
