import React from 'react';
import { Container } from './styles';

import Qr from './images/qr.jpg';

const UnSupported = () => (
  <Container>
    <p>
      该页面仅支持在微信客户端中运行，请扫码前往微信公众号访问
    </p>
    <img src={Qr} alt="云彩人资科研" />
  </Container>
);

export default UnSupported;
