/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'antd';
import { Container } from './styles';

export const MyModal = ({
  icon,
  noText,
  okText,
  onNo,
  onOk,
  title,
  level,
  paragraph,
  text,
  titleStyle,
  paragraphStyle,
  textStyle,
  okButtonStyle,
  noButtonStyle,
  noButtonAttrs,
  contentStyle,
  footer,
  content,
}) => (
  <Container style={contentStyle}>
    {icon && <div className="icon" style={{ backgroundImage: `url(${icon})` }} />}
    { title && (
    <div className={`title${level}`} style={titleStyle}>
      {title}
    </div>
    )}
    {paragraph && (
    <div className="paragraph" style={paragraphStyle}>
      {paragraph}
    </div>
    )}
    {text && (
    <div className="text" style={textStyle}>
      {text}
    </div>
    )}
    {content}
    <Row justify="center" align="middle" className="button-group" gutter={[20, 20]}>
      {noText && (
        <Col>
          <Button
            onClick={onNo}
            style={noButtonStyle}
            size="small"
            shape="round"
            { ...noButtonAttrs }
          >
            {noText}
          </Button>
        </Col>
      )}
      {okText && (
        <Col>
          <Button
            onClick={onOk}
            style={okButtonStyle}
            size="small"
            shape="round"
            type="primary"
          >
            {okText}
          </Button>
        </Col>
      )}
      {footer}
    </Row>
  </Container>
);

MyModal.propTypes = {
  icon: PropTypes.string,
  noText: PropTypes.string,
  okText: PropTypes.string,
  title: PropTypes.string,
  level: PropTypes.number,
  paragraph: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  text: PropTypes.string,
  onNo: PropTypes.func,
  onOk: PropTypes.func,
  titleStyle: PropTypes.shape(),
  paragraphStyle: PropTypes.shape(),
  textStyle: PropTypes.shape(),
  okButtonStyle: PropTypes.shape(),
  noButtonStyle: PropTypes.shape(),
  contentStyle: PropTypes.shape(),
  noButtonAttrs: PropTypes.shape(),
  footer: PropTypes.any,
  content: PropTypes.any,
};

MyModal.defaultProps = {
  icon: '',
  noText: '',
  okText: '',
  title: '',
  paragraph: '',
  text: '',
  level: 1,
  onNo: () => {},
  onOk: () => {},
  titleStyle: {},
  paragraphStyle: {},
  textStyle: {},
  okButtonStyle: {},
  noButtonStyle: {},
  contentStyle: {},
  noButtonAttrs: {},
  footer: null,
  content: null,
};
export default MyModal;
