export { default as localStorageKeys } from './localStorageKeys';
export const familyList = [
  '汉族',
  '壮族',
  '满族',
  '回族',
  '苗族',
  '维吾尔族',
  '土家族',
  '彝族',
  '蒙古族',
  '藏族',
  '布依族',
  '侗族',
  '瑶族',
  '朝鲜族',
  '白族',
  '哈尼族',
  '哈萨克族',
  '黎族',
  '傣族',
  '畲族',
  '傈僳族',
  '仡佬族',
  '东乡族',
  '高山族',
  '拉祜族',
  '水族',
  '佤族',
  '纳西族',
  '羌族',
  '土族',
  '仫佬族',
  '锡伯族',
  '柯尔克孜族',
  '达斡尔族',
  '景颇族',
  '毛南族',
  '撒拉族',
  '布朗族',
  '塔吉克族',
  '阿昌族',
  '普米族',
  '鄂温克族',
  '怒族',
  '京族',
  '基诺族',
  '德昂族',
  '保安族',
  '俄罗斯族',
  '裕固族',
  '乌孜别克族',
  '门巴族',
  '鄂伦春族',
  '独龙族',
  '塔塔尔族',
  '赫哲族',
  '珞巴族',
  '穿青人',
];
export const applyAmtList = [
  '50',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
  '1000',
  '1500',
  '2000',
  '3000',
  '4000',
];

export const transactionTypes = [
  {
    backendKey: 'OFFICIAL',
    key: 'official',
    keyword: '入',
    title: '',
  },
  {
    backendKey: 'DEDUCT',
    key: 'deduct',
    keyword: '扣',
    title: '扣款',
  },
  {
    backendKey: 'WITHDRAW',
    key: 'withdraw',
    keyword: '提',
    title: '提现',
  },
];

export const transactionStatus = [
  {
    backendKey: 'success',
    key: 'success',
    title: '成功',
  },
  {
    backendKey: 'running',
    key: 'running',
    title: '中',
  },
  {
    backendKey: 'failure',
    key: 'failure',
    title: '失败',
  },
];
export const amountFormat = '0,0.00';

export const timeFormat = 'YYYY-MM-DD HH:mm:ss';

export const minuteFormat = 'YYYY-MM-DD HH:mm';
