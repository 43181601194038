import styled from 'styled-components';
import { Carousel } from 'antd';
import { colors } from '@/styles';
import adv from '../images/advImg.png';
import homeIcon from '../images/homeIcon.png';
import backgroundImg from '../images/topBanner.jpg';
import identity from '../images/identity.png';
import jobCard from '../images/jobCard.png';
import salary from '../images/salary.png';

export const add = styled.div``;
export const Container = styled.div`
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 50px;
`;
export const Notice = styled.div`
  background-color: #fff;
  pointer-events: auto;
  border-radius: 12px;
  position: relative;
  width: 90%;
  max-width: 311px;
  margin: 0 auto;
  padding-bottom: 20px;
  .title {
    color: ${colors.primaryBlack};
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 6px;
  }
  .name {
    padding: 5px 24px 0 24px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
  }
  .content{
    padding: 0 24px;
    text-indent: 33px;
    font-size: 16px;
    font-weight: 400;
    .time {
      font-weight: 600;
    }
  }
  
`;
export const Welcome = styled.div`
  margin-bottom: 20px;
  p {
    font-size: 18px;
    font-weight: 600;
    color: ${colors.primaryBlack};
    line-height: 25px;
    margin-bottom:0px;
  }
  h3 {
    font-size: 14px;
    color: #737e8b;
    line-height: 20px;
    margin-top: 4px;
  }
`;
export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  padding-left: 18px;
  padding-right: 8px;
  padding-top: 20px;
  .content-box {
    width: 50%;
    height: 70px;
    margin-bottom: 10px;
    padding-right: 10px;
    display: flex;
  }
  .text {
    color: #fff
  }
  .ID {
    flex: 1;
    background: linear-gradient(114deg, #02CB9A 0%, #C5F7E9 100%);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
  }
  .CARD {
    flex: 1;
    background: linear-gradient(113deg, #62B7F4 0%, #D8EDFC 98%);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
  }
  .SALARY {
    flex: 1;
    background: linear-gradient(113deg, #FFB200 1%, #FFE292 100%);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
  }
  .CONTRACT {
    flex: 1;
    background: linear-gradient(113deg, #AE97FF 0%, #FCBAFF 100%);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
  }
  // li {
  //   position: relative;
  //   display: flex;
  //   height: 70px;
  //   width: 50%;
  //   flex-direction: column;
  //   align-items: center;
  //   background-repeat: no-repeat;
  //   background-position: center top;
  //   overflow: hidden;
  //   div {
  //     flex: 1;
  //     padding: 10px;
  //     border: 1px solid red;
  //     span {
  //       line-height: 22px;
  //       color:${colors.primaryBlack};
  //     }
  //   }
  //   &:before {
  //     content: '';
  //     width: 86px;
  //     height: 96px;
  //   }

  // }
`;
export const MyFooter = styled.footer`
  position: fixed;
  width: 100%;
  bottom: 0px;
  height: 50px;
  overflow: hidden;
  background:${colors.white}90;
  box-shadow: inset 0px 1px 0px 0px #DBDBDB;
  right: 0px;
  ul {
    display: flex;
    li {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 6px;
      p {
        font-size: 10px;
        color: ${colors.buttonShadow};
        line-height: 14px;
      }
      &:before {
        content: '';
        width: 22px;
        height: 22px;
        margin-bottom: 2px;
      }
      &.home:before {
        background-image: url(${homeIcon});
      }
    }
  }
`;

export const accountCardStyles = {
  title: { paddingTop: '32px' },
  balanceTitle: {
    height: '20px',
    fontSize: '14px',
    color: '#9AA4AF',
    lineHeight: '20px',
    paddingTop: '32px',
    paddingBottom: '10px',
  },
  container: {
    width: 'auto',
    height: '250px',
    maxWidth: '100%',
    borderRadius: 0,
    // boxShadow: '0px 2px 10px 0px rgba(230, 246, 255, 0.3)',
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  text: {
    height: '40px',
    fontSize: '28px',
    fontWeight: '600',
    color: '#002247',
    lineHeight: '40px',
    textAlign: 'center',
  },
};

export const wxTagStyles = {
  tag: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 9,
    opacity: 0,
  },
  content: {
    width: 200, height: 200, background: 'transparent',
  },
};

export default {};
