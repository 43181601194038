import styled from 'styled-components';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import { colors } from '@/styles';

export const HeaderContainer = styled.div`
  position: fixed;
  display: flex;
  z-index:1;
  width: 100%;
  align-items: center;
  height: 50px;
  font-size: 14px;
  background: #fff;
  border-bottom: 1px solid #f3f3f3;
`;

export const Title = styled(Typography.Title).attrs({ level: 1 })`
  flex:1;
  margin-bottom: 0 !important;
  text-align: center;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: ${colors.primaryBlack} !important;
`;

export const BackButton = styled.a`
  position: absolute;
  left: 0;
  padding: 0 16px;
`;

export default {};
