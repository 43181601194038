import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import notice from '../images/notice.png';

import { Notice } from './styles';

const NoticeModal = ({
  visible,
}) => (
  <Modal
    visible={visible}
    modalRender={() => (
      <Notice>
        <img src={notice} alt="" />
        <p className="title">系统升级维护中...</p>
        <p className="name">
          尊敬的用户：
        </p>
        <p className="content">
          感谢您一直以来对我们的支持，为了给您带来更好的体验，我们将于
          <span className="time">10月10日下午17:30-18:00</span>
          进行系统升级服务，升级期间暂时无法使用，升级完成后恢复访问。
        </p>
      </Notice>
    )}
  />
);

NoticeModal.propTypes = {
  visible: PropTypes.bool,
};

NoticeModal.defaultProps = {
  visible: false,
};

export default observer(NoticeModal);
