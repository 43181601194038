import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, Row, Col, message,
} from 'antd';
import { observer } from 'mobx-react-lite';
import wx from 'weixin-js-sdk';
import { Popup } from '@/components';
import { localStorageKeys } from '@/constants';
import authStore from '@/stores/auth';
import binding from '../images/binding.png';

import { wxTagStyles } from './styles';
import { httpRequest } from '@/utils';

const RebindConfirmModal = ({
  visible,
  onConfirm,
  onClose,
  isAuth,
  onLaunched,
}) => {
  const weAppRef = useRef(null);
  const { company, profile } = authStore;
  const [miniProgramPath, setMiniProgramPath] = useState('');
  const getUrl = async () => {
    try {
      const res = await httpRequest.get('/client/staff/payrollCard/auth/url');
      if (res.code === 0) {
        setMiniProgramPath(res.data);
      } else {
        throw new Error(res.msg);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const handleMiniProgramLaunched = () => {
    onClose();
    setTimeout(() => {
      onLaunched();
    }, 500);
  };

  useEffect(() => {
    if (visible) {
      getUrl();
      const error = () => {
        message.error('打开小程序失败，请重试');
      };
      wx.ready(() => {
        weAppRef.current?.addEventListener('launch', handleMiniProgramLaunched);
        weAppRef.current?.addEventListener('error', error);
      });
      wx.error((res) => {
        console.log(res);
        // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名
      });

      return () => {
        weAppRef.current?.removeEventListener('launch', handleMiniProgramLaunched);
        weAppRef.current?.removeEventListener('error', error);
      };
    }
    return null;
  }, [visible]);

  // const miniProgramPath = `pages/wechatAuth/wechatAuth?from=h5&userId=${localStorage.getItem(localStorageKeys.openId)}&companyCode=${company?.code}&idNo=${profile?.idNo}&name=${profile?.name}`;

  return (
    <Modal
      visible={visible}
      modalRender={() => (
        <Popup
          icon={binding}
          title="绑定微工卡"
          paragraph="前往完成实名认证并绑定微工卡后，领取您的个人薪资"
          contentStyle={{
            paddingTop: '60px',
            background: 'linear-gradient(180deg, #FFF3F0 0%, #FFFFFF 100%)',
          }}
          footer={(
            <Row
              justify="center"
              align="middle"
              gutter={[20, 20]}
            >
              <Col>
                <Button
                  size="small"
                  shape="round"
                  onClick={onClose}
                >
                  以后再说
                </Button>
              </Col>
              <Col style={{ overflow: 'hidden' }}>
                {
                  isAuth ? (
                    <>
                      <wx-open-launch-weapp
                        ref={weAppRef}
                        username={process.env.REACT_APP_WECHAT_MINI_PROGRAM_ID}
                        path={miniProgramPath}
                        env-version={process.env.REACT_APP_WECHAT_MINI_PROGRAM_ENV}
                        style={wxTagStyles.tag}
                      >
                        <script type="text/wxtag-template">
                          <div style={wxTagStyles.content} />
                        </script>
                      </wx-open-launch-weapp>
                      <Button
                        size="small"
                        shape="round"
                        type="primary"
                      >
                        立即前往
                      </Button>
                    </>
                  ) : (
                    <Button
                      size="small"
                      shape="round"
                      type="primary"
                      onClick={onConfirm}
                    >
                      立即前往
                    </Button>
                  )
                }
              </Col>
            </Row>
            )}
        />
      )}
    />
  );
};

RebindConfirmModal.propTypes = {
  visible: PropTypes.bool,
  onConfirm: PropTypes.func,
  onLaunched: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  isAuth: PropTypes.bool,
};

RebindConfirmModal.defaultProps = {
  visible: false,
  onConfirm: () => {},
  onLaunched: () => {},
  isAuth: false,
};

export default observer(RebindConfirmModal);
