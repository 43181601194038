import { makeAutoObservable } from 'mobx';

class Auth {
  company = {
    tenantId: '',
    tenantName: '',
    code: '',
  }

  // 以下数据仅能用于埋点
  coverPoint = {

  }

  profile = {
    idNo: '',
    name: '',
  }

  isAuth = false

  constructor() {
    makeAutoObservable(this);
  }

  setIsAuth(isAuth) {
    this.isAuth = isAuth;
  }

  setCompany(company) {
    this.company = {
      ...this.company,
      ...company,
    };
  }

  setProfile(profile) {
    this.profile = {
      ...this.profile,
      ...profile,
    };
  }

  // 以下数据仅能用于埋点
  setCoverPoint(coverPoint) {
    this.coverPoint = {
      ...this.coverPoint,
      ...coverPoint,
    };
  }
}

export default new Auth();
