export default Object.freeze({
  transparent: 'transparent',
  primary: '#1BADFB',
  primaryLight: '#6BBDFF',
  primaryLighter: '#E1F2FF',
  primaryWhite: '#AFDBFF',
  primaryBlack: '#002247',
  black: '#000000',
  white: '#FFFFFF',
  red: '#DE1717',
  magenta: '#EF6060',
  orange: '#FF830B',
  yellow: '#EBB464',
  yellowLight: '#F5B253',
  tomato: '#E43700',
  background: '#F3F3F3',
  backgroundDark: '#F5F5F5',
  backgroundDarker: '#F2F2F2',
  border: '#EFEEEE',
  borderBottom: ' #F5F8FB',
  tabBorder: '#D8D8D8',
  textPrimary: '#333333',
  textSecondary: '#666666',
  textTertiary: '#737E8B',
  textDisabled: '#CDCDCD',
  subText: '#9AA4AF',
  borderShadow: '#E6F6FF',
  buttonShadow: '#1BADFB',
});
