import React, {lazy} from 'react';
import {DefaultLayout} from '@/layouts';
import appRoutes from './appRoutes';

const NotFound = lazy(() => import('@modules/error/notFound'));
const PositionScan = lazy(() => import('@modules/scan/position'));

export default [
  {
    path: '/',
    element: <DefaultLayout />,
    children: appRoutes,
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: 'positionScan',
    element: <PositionScan />,
  }
];
