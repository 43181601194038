import styled from 'styled-components';
import { Carousel } from 'antd';
import { colors } from '@/styles';
import adv from '../images/advImg.png';
import homeIcon from '../images/homeIcon.png';
import advanceIcon from '../images/advance.png';
import backgroundImg from '../images/backgroundImg.png';
import identity from '../images/identity.png';
import jobCard from '../images/jobCard.png';
import salary from '../images/salary.png';

export const add = styled.div``;
export const Container = styled.div`
  padding: 20px 16px 16px 16px;
  background-image: url(${backgroundImg});
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 50px;
`;
export const Welcome = styled.div`
  margin-bottom: 20px;
  p {
    font-size: 18px;
    font-weight: 600;
    color: ${colors.primaryBlack};
    line-height: 25px;
    margin-bottom:0px;
  }
  h3 {
    font-size: 14px;
    color: #737e8b;
    line-height: 20px;
    margin-top: 4px;
  }
`;
export const Menu = styled.li`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: ${colors.white};
  box-shadow: ${colors.borderShadow}30;
  border-radius: 12px;
  margin-top: 20px;
  padding: 0px 16px;

  li {
    position: relative;
    display: flex;
    width: 30%;
    flex-direction: column;
    align-items: center;
    padding: 0px 14px 14px;
    background-repeat: no-repeat;
    background-position: center top;
    overflow: hidden;
    span {
      margin-top: -22px;
      line-height: 22px;
      color:${colors.primaryBlack};
    }
    &:before {
      content: '';
      width: 86px;
      height: 96px;
    }
    &.ID {
      background-image: url(${identity});
    }
    &.CARD {
      background-image: url(${jobCard});
    }
    &.SALARY {
      background-image: url(${salary});
    }
  }
`;
export const StyledCarousel = styled(Carousel)`
  margin-top: 20px;
  .item {
    position: relative;
    display: block;
    border-radius: 8px;
    overflow: hidden;
    &:after {
      content: '';
      display: block;
      padding-top: 27%;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
    }
  }
`;
export const MyFooter = styled.footer`
  position: fixed;
  width: 100%;
  bottom: 0px;
  height: 50px;
  overflow: hidden;
  background:${colors.white}90;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  box-shadow: inset 0px 1px 0px 0px #DBDBDB;
  right: 0px;
  ul {
    display: flex;
    Link {
      width: 50%;
      display: block;
    }
    li {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 6px;
      p {
        font-size: 10px;
        color: '#9AA4AF';
        line-height: 14px;
        &.active {
          color: ${colors.buttonShadow};
        }
      }
      
      &:before {
        content: '';
        width: 22px;
        height: 22px;
        margin-bottom: 2px;
      }
      &.home:before {
        background-image: url(${homeIcon});
      }
      &.advance:before {
        background-image: url(${advanceIcon});
      }
    }
  }
`;

export const accountCardStyles = {
  title: { paddingTop: '32px' },
  balanceTitle: {
    height: '20px',
    fontSize: '14px',
    color: '#9AA4AF',
    lineHeight: '20px',
    paddingTop: '32px',
    paddingBottom: '10px',
  },
  container: {
    width: 'auto',
    maxWidth: '100%',
    boxShadow: '0px 2px 10px 0px rgba(230, 246, 255, 0.3)',
  },
  text: {
    height: '40px',
    fontSize: '28px',
    fontWeight: '600',
    color: '#002247',
    lineHeight: '40px',
    textAlign: 'center',
  },
};

export const wxTagStyles = {
  tag: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 9,
    opacity: 0,
    marginLeft: '-14px',
  },
  content: {
    width: 200, height: 200, background: 'transparent',
  },
};

export default {};
