import axios from 'axios';
import authStore from '@/stores/auth';
import { localStorageKeys } from '@/constants';

const aixosTrack = async (data, link) => {
  return; //该版本上线无需埋点，后续需要再打开
  const params = {
    project: 'FINANCE',
    time: new Date().getTime(),
    ...data,
    ...authStore.coverPoint,
    tenantId: sessionStorage.getItem(localStorageKeys.tenantId),
    openId: sessionStorage.getItem(localStorageKeys.openId),
  };
  if (authStore.profile.idNo) {
    params.idNo = authStore.profile.idNo;
    params.name = authStore.profile.name;
  }
  axios.defaults.baseURL = process.env.REACT_APP_TRACK_API_ENDPOINT;
  try {
    const res = await axios.post('/track-backend/common/trace/wechat', params);
    if (res.code !== 0) {
      throw new Error(res.msg);
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    if (link) {
      window.location.href = link;
    }
  }
};

export default aixosTrack;
