import React from "react";
import { message } from "antd";
import { observer } from "mobx-react-lite";

import NoticeModal from "./noticeModal";
import "./index.less";

const Guide = () => {
  const handleErrorMessage = () => {
    message.warning("请从企业公众号进入云彩人资科研平台!");
  };

  return (
    <>
      <div className="guide-container">
        <div className="top-box">
          <p>
            您好！
          </p>
        </div>
        <div className="bottom-box">
          <div className="money-box">
            <div className="content-box">
              <text className="title">云彩人资科研</text>
              <text>请从企业公众号进入云彩人资科研平台</text>
            </div>
          </div>
          <div className="function-box">
            <div onClick={handleErrorMessage} className="content-box">
              <div className="inner-content-box-info">
                <span className="text">信息管理</span>
                <div className="info-image"></div>
              </div>
            </div>
            <div onClick={handleErrorMessage} className="content-box">
              <div className="inner-content-wgk-info">
                <span className="text">微工卡</span>
                <div className="wgk-image"></div>
              </div>
            </div>
            <div onClick={handleErrorMessage} className="content-box">
              <div className="inner-content-salary-info">
                <span className="text">工资条</span>
                <div className="salary-image"></div>
              </div>
            </div>
            <div onClick={handleErrorMessage} className="content-box">
              <div className="inner-content-contract-info">
                <span className="text">电子合同</span>
                <div className="contract-image"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NoticeModal visible={process.env.REACT_APP_ONLINE} />
    </>
  );
};

export default observer(Guide);
