/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-spacing */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

import documentStore from '@stores/document';

const ProtectedRoute = ({
  component,
  title,
}) => {
  useEffect(() => {
    document.title = process.env.REACT_APP_WEBSITE_TITLE;
    documentStore.setPageTitle(process.env.REACT_APP_WEBSITE_TITLE);
  }, [title]);

  return component;
};

ProtectedRoute.propTypes = {
  component: PropTypes.node.isRequired,
  title: PropTypes.string,
};

ProtectedRoute.defaultProps = {
  title: '',
};

export default observer(ProtectedRoute);
