import styled from 'styled-components';
import { colors } from '@/styles';

export const Container = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 50px 24px;
  p {
    width: 250px;
    text-align: center;
    font-size: 14px;
  }
  img {
    width: 250px;
  }
`;
export default {};
